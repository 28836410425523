import { NotificationCell_MissingHostNotificationFragment } from "@generated/graphql";
import { triggerCustomToast } from "@shared/Toast";
import toast from "react-hot-toast";
import { NotificationContent } from "./NotificationContent";

export const DROPPED_HOST_TOAST_ID_TEMPLATE = "dropped-host";

type DroppedHostAlertProps = {
  notification: NotificationCell_MissingHostNotificationFragment;
  onDismiss: (userId: string, meetingId: string) => void;
};

export const triggerDroppedHostAlert = ({
  notification,
  onDismiss,
}: DroppedHostAlertProps) => {
  const handleDismiss = () => {
    onDismiss(notification.userId, notification.meetingId);
    dismissDroppedHostAlert(notification.meetingId);
  };

  const handleSilence = () => {
    dismissDroppedHostAlert(notification.meetingId);
  };

  triggerCustomToast(
    <div className="right-0 z-10 mt-2 w-[550px] rounded-md overflow-hidden shadow-lg divide-y divide-gray-200 even:bg-white/[0.96] odd:bg-slate-100/[0.96]">
      <NotificationContent
        notification={notification}
        showSilence={true}
        onDismiss={handleDismiss}
        onSilence={handleSilence}
      />
    </div>,
    {
      position: "top-right",
      duration: Infinity,
      style: {
        zIndex: 9999,
        marginTop: "100px",
      },
      id: `${DROPPED_HOST_TOAST_ID_TEMPLATE}-${notification.meetingId}`,
    }
  );
};

export const dismissDroppedHostAlert = (meetingId: string) => {
  toast.remove(`${DROPPED_HOST_TOAST_ID_TEMPLATE}-${meetingId}`);
};
