import { VisitorBreadcrumbs_VisitorFragment } from "@generated/graphql";
import { getSubjectText } from "@utils/labels";
import { Route, Routes } from "@utils/routes";
import {
  getSubjectAllCombo,
  getSubjectComboKey,
} from "components/shared/AttendanceGrades/utils";
import { VisitorEngagementOption, VisitorRecordOption } from "./types";

export const getEngagementSelectOptions = (
  engagements: VisitorBreadcrumbs_VisitorFragment["engagements"]
): VisitorEngagementOption[] =>
  (engagements ?? []).map((engagement) => ({
    id: engagement.id,
    value: engagement.name,
    limitedAccessKey: engagement.limitedAccessKey ?? undefined,
  }));

export const getRecordSelectOptions = (
  engagement?: VisitorBreadcrumbs_VisitorFragment["engagements"][0]
): VisitorRecordOption[] =>
  engagement
    ? [
        {
          id: `${engagement.id}-student-attendance`,
          value: "Student Attendance",
          route: Routes.engagement.publicStudentAttendancePage,
          routeProps: [(engagement.limitedAccessKey as string) ?? "unknown"],
        },
        ...(engagement.enableStudentGrading
          ? engagement.cohortSubjects.map((subject) => ({
              id: `${engagement.id}-${subject}-grades`,
              value: `${getSubjectText(subject)} Grades`.trim(),
              route: Routes.engagement.publicStudentGrades.subjectSelected,
              routeProps: [
                (engagement.limitedAccessKey as string) ?? "unknown",
                getSubjectComboKey(getSubjectAllCombo(subject)),
              ],
            }))
          : []),
        {
          id: `${engagement.id}-engagement-roster`,
          value: "Engagement Roster",
          route: Routes.visitorRoster,
          routeProps: [(engagement.limitedAccessKey as string) ?? "unknown"],
        },
      ]
    : [];

export const getRecordIndex = (
  activeRoute: Route,
  activeURL: string,
  recordOptions: VisitorRecordOption[]
) => {
  switch (activeRoute) {
    case Routes.engagement.publicStudentAttendancePage:
      return 0;
    case Routes.engagement.publicStudentGrades.subjectSelected:
      return (
        recordOptions.findIndex(
          (option) =>
            Array.isArray(option.routeProps) &&
            option.routeProps[1] === activeURL.split("/").pop()
        ) ?? 0
      );
    case Routes.visitorRoster:
      return recordOptions.length - 1;
    default:
      return 0;
  }
};
