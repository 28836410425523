import { Routes } from "@utils/routes";
import { IconMap } from "components/shared/Icon";
import compact from "lodash/compact";
import { TeacherJobBoardIndicatorBadges } from "../components/counters";
import { MTConcernsCountBadge } from "../components/counters/MTConcernsCountBadge";
import { MTShoutoutsCountBadge } from "../components/counters/MTShoutoutsCountBadge";
import { MyPlacementsCountBadges } from "../components/counters/MyPlacementsCountBadges";
import { SidebarLink, SubMenuPosition } from "../types";

export const getCommonTeacherLinks = (currentPathname?: string) =>
  [
    {
      name: "Dashboard",
      route: Routes.home,
      current: Routes.home.path() === currentPathname,
      icon: IconMap["dashboard"],
    },
    {
      name: "Engagements",
      route: Routes.engagements,
      current: currentPathname?.startsWith(Routes.engagements.path()),
      icon: IconMap["engagement"],
    },
    {
      name: "My Schedule",
      route: Routes.mySchedule,
      current: Routes.mySchedule.path() === currentPathname,
      icon: IconMap["calendar"],
    },
  ] as SidebarLink[];

export const getMentorTeacherLinks = (currentPathname?: string) => {
  const links = getCommonTeacherLinks(currentPathname);

  links.splice(
    1,
    0,
    {
      name: "Shoutouts",
      route: Routes.shoutouts,
      iconClassName: "scale-x-[-1]",
      subMenuPosition: SubMenuPosition.First,
      current: Routes.shoutouts.path() === currentPathname,
      icon: IconMap["shoutout"],
      counter: MTShoutoutsCountBadge,
    },
    {
      name: "Concerns",
      route: Routes.concerns,
      iconClassName: "scale-x-[-1]",
      subMenuPosition: SubMenuPosition.Last,
      current: Routes.concerns.path() === currentPathname,
      icon: IconMap["concern"],
      counter: MTConcernsCountBadge,
    }
  );

  return links;
};

export const getTeacherTutorLinks = (
  hidePaymentDetails: boolean,
  currentPathname?: string
) =>
  compact([
    ...getCommonTeacherLinks(currentPathname),
    {
      name: "My Placements",
      route: Routes.myPlacements,
      icon: IconMap["timeLapse"],
      current: Routes.myPlacements.path() === currentPathname,
      counter: MyPlacementsCountBadges,
    },
    {
      name: "Job Board",
      route: Routes.job.board,
      current: currentPathname?.startsWith("/job/"),
      icon: IconMap["job"],
      counter: TeacherJobBoardIndicatorBadges,
    },
    {
      name: "My Attendance",
      route: Routes.myAttendance,
      current: Routes.myAttendance.path() === currentPathname,
      icon: IconMap["attendance"],
    },
    hidePaymentDetails
      ? null
      : {
          name: "My Payments",
          route: Routes.myPayments,
          current: Routes.myPayments.path() === currentPathname,
          icon: IconMap["payment"],
        },
    {
      name: "My PD",
      route: Routes.myPD,
      current: Routes.myPD.path() === currentPathname,
      icon: IconMap["teacher"],
    },
    {
      name: "My Profile",
      route: Routes.myProfile,
      current: Routes.myProfile.path() === currentPathname,
      icon: IconMap["profile"],
    },
  ]) as SidebarLink[];
