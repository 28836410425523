import { Routes } from "@utils/routes";
import { IconMap } from "components/shared/Icon";
import { AdminMode } from "types/global";
import { LiveCohortsCountBadge } from "../components/counters/LiveCohortsCountBadge";
import { LiveEngagementsCountBadge } from "../components/counters/LiveEngagementsCountBadge";
import { SidebarLink, SubMenuPosition } from "../types";
import {
  getEngagementCurrentRoute,
  getOrganizationCurrentRoute,
} from "./utils";

export const getAdminLinks = (mode: AdminMode, currentPathname: string) => {
  switch (mode) {
    case AdminMode.Dashboard:
      return [];
    case AdminMode.Managing:
      return getAdminManageLinks(currentPathname);
    case AdminMode.Staffing:
      return getAdminStaffingLinks(currentPathname);
    case AdminMode.ControlPanel:
      return getAdminControlPanelLinks(currentPathname);
    default:
      return getAdminManageLinks(currentPathname);
  }
};

export const getAdminManageLinks = (currentPathname: string) =>
  [
    {
      name: "Organizations",
      route: Routes.organizations,
      icon: IconMap["organization"],
      subMenuPosition: SubMenuPosition.First,
      current: getOrganizationCurrentRoute(currentPathname),
    },
    {
      name: "Engagements",
      route: Routes.engagements,
      icon: IconMap["engagement"],
      counter: LiveEngagementsCountBadge,
      subMenuPosition: SubMenuPosition.Middle,
      current: getEngagementCurrentRoute(currentPathname),
    },
    {
      name: "Cohorts",
      route: Routes.cohorts,
      icon: IconMap["cohort"],
      counter: LiveCohortsCountBadge,
      subMenuPosition: SubMenuPosition.Middle,
      current: currentPathname?.startsWith(Routes.cohorts.path()),
    },
    {
      name: "Students",
      route: Routes.students,
      icon: IconMap["student"],
      subMenuPosition: SubMenuPosition.Middle,
      current: currentPathname?.startsWith(Routes.students.path()),
    },
    {
      name: "Alerts",
      route: Routes.triggers,
      icon: IconMap["trigger"],
      subMenuPosition: SubMenuPosition.Middle,
      current: Routes.triggers.path() === currentPathname,
    },
    {
      name: "Users",
      route: Routes.users,
      icon: IconMap["users"],
      subMenuPosition: SubMenuPosition.Last,
      current: currentPathname?.includes(Routes.users.path()),
    },
  ] as SidebarLink[];

export const getAdminStaffingLinks = (currentPathname: string) =>
  [
    {
      name: "Job Board",
      icon: IconMap["job"],
      route: Routes.job.board,
      subMenuPosition: SubMenuPosition.First,
      current: currentPathname?.startsWith("/job/"),
    },
    {
      name: "Organizations",
      route: Routes.organizations,
      icon: IconMap["organization"],
      subMenuPosition: SubMenuPosition.Middle,
      current: getOrganizationCurrentRoute(currentPathname),
    },
    {
      name: "Engagements",
      route: Routes.engagements,
      icon: IconMap["engagement"],
      subMenuPosition: SubMenuPosition.Middle,
      current: getEngagementCurrentRoute(currentPathname),
    },
    {
      name: "Teachers",
      route: Routes.users,
      icon: IconMap["teacher"],
      subMenuPosition: SubMenuPosition.Last,
      current: currentPathname?.includes(Routes.users.path()),
    },
  ] as SidebarLink[];

export const getAdminControlPanelLinks = (currentPathname: string) =>
  [
    {
      name: "QA App",
      route: Routes.qaApp,
      icon: IconMap["qaApp"],
      subMenuPosition: SubMenuPosition.First,
      current: currentPathname?.includes(Routes.qaApp.path()),
    },
    {
      name: "Zoom Client",
      icon: IconMap["videoProvider"],
      route: Routes.zoomClientConfiguration,
      subMenuPosition: SubMenuPosition.Middle,
      current: currentPathname?.includes(Routes.zoomClientConfiguration.path()),
    },
    {
      name: "Settings",
      icon: IconMap["settings"],
      route: Routes.settings.usHolidays,
      subMenuPosition: SubMenuPosition.Last,
      current: currentPathname?.startsWith("/settings/"),
    },
  ] as SidebarLink[];
