import { Routes } from "@utils/routes";
import { getSpoofUser } from "@utils/withFragments/spoof";
import { useAuth } from "contexts/AuthProvider";
import { AgreementModal } from "./components/AgreementModal";

export function PendingAgreementContainer() {
  const spoofUser = getSpoofUser();
  const { user, setActiveRoute } = useAuth();

  const onClose = async () =>
    Routes.myProfile.accountRoleAccess.includes(user?.accountRole) &&
    setActiveRoute(Routes.myProfile.href());

  return user?.hasPendingAgreements && !spoofUser ? (
    <AgreementModal onClose={onClose} />
  ) : null;
}
