import { Routes } from "@utils/routes";
import clsx from "clsx";
import { Icon } from "components/shared/Icon";
import { useAuth } from "contexts/AuthProvider";

type Props = { organizationId: string | undefined };

export const VisitorSpoofButton = ({ organizationId }: Props) => {
  const { isAdmin, setActiveRoute } = useAuth();

  const handleVisitorSpoofRedirect = () =>
    organizationId && setActiveRoute(Routes.org.visitors, [organizationId]);

  return isAdmin && organizationId ? (
    <div
      onClick={handleVisitorSpoofRedirect}
      className={clsx(
        "relative flex flex-center h-9 gap-2 border border-gray-500 px-2 mr-5 rounded-md",
        "hover:bg-rose-900 hover:border-rose-500 cursor-pointer bg-gray-600 text-white group"
      )}
    >
      <div className="flex items-center h-full justify-center">
        <Icon icon="ghost" size={5} color="text-violet-400" />
      </div>
      <p className="text-base whitespace-nowrap mr-px">Spoof Visitor</p>
      <Icon
        size={4}
        icon="remove"
        color="text-rose-400 group-hover:text-rose-500"
      />
    </div>
  ) : null;
};
