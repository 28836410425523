import clsx from "clsx";
import { SubMenuPosition } from "./types";

export const renderSubmenuBranches = (
  isAdmin: boolean,
  active: boolean,
  position?: SubMenuPosition
) => {
  const { First, Last, Only } = SubMenuPosition;

  return (
    <div
      className={clsx(
        "flex h-full text-gray-400",
        isAdmin ? "w-4" : "w-[34px] pl-[20px]"
      )}
    >
      {/* Vertical Line Extension connecting first SubMenuOption to ParentMenuOption */}
      {(position === First || position === Only) && (
        <div className="absolute w-1 h-[6px] bottom-full border-l" />
      )}
      {/* L Shaped Line Connecting Above Option to this SubMenuOption */}
      <div
        className={clsx(
          "absolute border-l border-b top-0 h-1/2",
          isAdmin ? "w-[14px]" : "w-[20px]"
        )}
      />
      {/* Active Blue Highlight */}
      {active && (
        <div
          className={clsx(
            "absolute flex z-2 h-10 top-[5px] w-[10px] bg-blue-600 rounded-l-md",
            isAdmin ? "left-[9px]" : "left-[30px]"
          )}
        />
      )}
      {/* Bottom Vertical Sideline Extension (To Connect to SubMenuOption Below) */}
      {position !== Last && position !== Only && (
        <div className="absolute w-1 border-l bottom-0 h-1/2" />
      )}
    </div>
  );
};
