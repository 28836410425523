import { CognitoUser } from "@aws-amplify/auth";
import { removeCognitoCookies } from "@utils/cookies";
import { Routes, getAccountRoleFallbackRoute } from "@utils/routes";
import { assertUnreachable } from "@utils/types";
import { ErrorBox, FieldError, Link, Spinner } from "components/shared";
import { LoginStatus, useAuth } from "contexts/AuthProvider";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { CompleteNewPasswordPage } from "./CompleteNewPasswordPage";
import { CenteredLogoContainer } from "./components/CenteredLogoContainer";
import { LoginButtonAndIcon } from "./components/LoginButtonAndIcon";
import { AuthStatus, LoginInputs } from "./types";

export function LoginPage() {
  const auth = useAuth();
  const { setActiveRoute } = useAuth();
  const [status, setStatus] = useState<AuthStatus>("idle");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [loginFailure, setLoginFailure] = useState<string | null>(null);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>({ mode: "onSubmit" });

  const onSubmit: SubmitHandler<LoginInputs> = async ({ email, password }) => {
    try {
      removeCognitoCookies();
      setStatus("loading");
      setLoginFailure(null);
      const { status, message, cognitoUser, accountRole } = await auth.login(
        email,
        password
      );

      switch (status) {
        case LoginStatus.Success:
          setStatus("success");
          setActiveRoute(
            accountRole ? getAccountRoleFallbackRoute(accountRole) : Routes.home
          );
          break;
        case LoginStatus.ChangePassword:
          setShowChangePassword(true);
          setCognitoUser(cognitoUser);
          break;
        case LoginStatus.Failure:
          setStatus("idle");
          setLoginFailure(message);
          break;
        default:
          return assertUnreachable(status as never, "status");
      }
    } catch {
      setStatus("idle");
      setLoginFailure("Login attempt failed.");
    }
  };

  return (
    <>
      {showChangePassword && cognitoUser ? (
        <CompleteNewPasswordPage cognitoUser={cognitoUser} />
      ) : (
        <CenteredLogoContainer headerText="Sign in to your account">
          <form
            className="flex flex-col gap-y-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Email is required.",
                    },
                  })}
                  type="email"
                  autoComplete="email"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-xs placeholder-gray-400 focus:outline-hidden focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                />
                <FieldError msg={errors.email?.message} />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Password is required.",
                    },
                  })}
                  type="password"
                  autoComplete="current-password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-xs placeholder-gray-400 focus:outline-hidden focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                />
                <FieldError msg={errors.password?.message} />
              </div>
              <div className="flex items-center justify-end mt-1 mb-2 mr-1">
                <div className="text-sm">
                  <Link
                    route={Routes.forgotPassword}
                    className="font-medium text-gray-600 hover:text-gray-500"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
            </div>

            <ErrorBox msg={loginFailure} />

            <LoginButtonAndIcon
              Icon={getSignInIcon}
              status={status}
              text="Sign in"
            />
          </form>
        </CenteredLogoContainer>
      )}
    </>
  );
}

const getSignInIcon = ({ status }: { status: AuthStatus }) => {
  switch (status) {
    case "idle":
      return <FaLock className="w-5 h-5 text-white" aria-hidden="true" />;
    case "success":
      return <FaLockOpen className="w-5 h-5" />;
    case "loading":
      return <Spinner />;
    default:
      return assertUnreachable(status, "Status");
  }
};
