import { User } from "@generated/graphql";
import { CopyText, FieldError, Input } from "@shared";

type Props = {
  userFullName: User["fullName"];
  value: string;
  required: boolean;
  error: string | null;
  setValue: (value: string) => void;
};

export function AgreementSignature({
  userFullName,
  value,
  required,
  error,
  setValue,
}: Props) {
  return (
    <div className="flex grow flex-col gap-1">
      <div className="flex justify-between items-center leading-tight flex-wrap">
        <span className="flex gap-x-2 text-lg font-medium text-gray-700 whitespace-nowrap">
          Sign
          <div onClick={() => setValue(userFullName)}>
            <CopyText text={userFullName} textClassName="text-blue-600" />
          </div>
          below to accept the offer:
        </span>
      </div>
      <div className="flex flex-row gap-x-2 items-center">
        <Input
          className="flex grow"
          id={"Accept"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {!required && (
          <span className="text-lg font-medium text-gray-700">(Optional)</span>
        )}
      </div>
      {error && <FieldError msg={error} />}
    </div>
  );
}
