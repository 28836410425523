import { sidebarTooltipStyles } from "@utils/styleStrings";
import clsx from "clsx";
import { Spinner, Tooltip } from "components/shared";
import { useSidebarData } from "contexts/SidebarDataProvider";

export const MTConcernsCountBadge = () => {
  const { sidebarLoading, unprocessedConcernsCount } = useSidebarData();

  return (
    <div className="flex pr-2">
      {sidebarLoading ? (
        <Spinner />
      ) : (
        unprocessedConcernsCount > 0 && (
          <Tooltip
            content="Unprocessed Concerns Count"
            tooltipProps={sidebarTooltipStyles}
          >
            <div
              className={clsx(
                "flex relative flex-center h-[25px] rounded-full leading-none w-fit px-[2px]",
                "text-white-500 border border-white-500 font-light text-center min-w-[25px]",
                unprocessedConcernsCount > 99 ? "text-[10px]" : "text-[13px]"
              )}
            >
              <p className="leading-none">{unprocessedConcernsCount}</p>
            </div>
          </Tooltip>
        )
      )}
    </div>
  );
};
