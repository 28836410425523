import {
  Cohort,
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  HolidayType,
  LimitedAccessCohort,
  LimitedAccessCohortStudent,
  LimitedAccessStudent,
  Student,
  StudentAttendanceGradingItem_CohortSessionStudentGradingFragment,
  StudentAttendanceGradingItem_StudentGradingRecordFragment,
} from "@generated/graphql";
import { AttendanceIconType } from "@shared/indicators/AttendanceIcon";
import { CellRendererHoliday } from "@shared/NavigationCalendars";
import { ISODate, LocalizedWeekday } from "@utils/dateTime";
import { SelectMenuOption } from "../Inputs/SelectMenu";

export type StudentGrades =
  | StudentAttendanceGradingItem_StudentGradingRecordFragment
  | StudentAttendanceGradingItem_CohortSessionStudentGradingFragment;

export type DraftNotes = { id: string; notes: string };

export type StudentLiveParticipantMatchDistance = {
  participant: string;
  distance: number;
};

export type StudentLiveParticipantMatch = {
  studentId: Student["id"];
  studentFullName: Student["fullName"];
  match: string;
  distances: StudentLiveParticipantMatchDistance[];
};

export type StudentLiveParticipantMatches = {
  [attendanceKey: string]: StudentLiveParticipantMatch;
};

export type StudentDisplayNames = {
  [cohortSessionStudentId: string]: string | undefined;
};

export type StudentAttendanceStyleData = {
  peepColor: string;
  iconType: AttendanceIconType;
  pillColor: string;
};

export type SubjectOption = SelectMenuOption<{
  subject: CohortAssignmentSubject;
}>;

export type SubSubjectOption = SelectMenuOption<{
  subSubject: CohortAssignmentSubSubject;
}>;

export type SubSubjectAllOption = SelectMenuOption<{
  subSubject: CohortAssignmentSubSubject | typeof ANY_SUB_SUBJECT;
}>;

export const ANY_SUB_SUBJECT = "All";

export type SubjectComboKey = `${CohortAssignmentSubject}-${
  | CohortAssignmentSubSubject
  | typeof ANY_SUB_SUBJECT}`;

export type SubjectComboWithAll = {
  subject: CohortAssignmentSubject;
  subSubject: CohortAssignmentSubSubject | null;
};

export enum StudentGradesColumnKey {
  Student = "Student",
  Grade = "Grade",
  Cohort = "Cohort",
  SubjectAttendanceAverage = "Attend. Avg.",
  ClassworkGradeAverage = "Classwork Avg.",
  ParticipationGradeAverage = "Partici. Avg.",
  ReadingGradeAverage = "Reading Avg.",
  LanguageArtsGradeAverage = "Lang. Arts. Avg.",
}

export type StudentStatColumn =
  | StudentGradesColumnKey.SubjectAttendanceAverage
  | StudentGradesColumnKey.ClassworkGradeAverage
  | StudentGradesColumnKey.ParticipationGradeAverage
  | StudentGradesColumnKey.ReadingGradeAverage
  | StudentGradesColumnKey.LanguageArtsGradeAverage;

export type StudentGradesSort = {
  columnKey: StudentGradesColumnKey;
  isSortedDesc: boolean;
};

export type StudentGridData = {
  [StudentGradesColumnKey.Student]: Pick<
    LimitedAccessStudent,
    "id" | "fullName" | "externalId"
  > &
    Pick<LimitedAccessCohortStudent, "createdAt">;
  [StudentGradesColumnKey.Grade]: LimitedAccessCohort["instructionLevel"];
  [StudentGradesColumnKey.Cohort]: Pick<LimitedAccessCohort, "id" | "name">;
  averages: {
    [key in SubjectComboKey]?: {
      [StudentGradesColumnKey.SubjectAttendanceAverage]: number | null;
      [StudentGradesColumnKey.ClassworkGradeAverage]: number | null;
      [StudentGradesColumnKey.ParticipationGradeAverage]: number | null;
      [StudentGradesColumnKey.ReadingGradeAverage]: number | null;
      [StudentGradesColumnKey.LanguageArtsGradeAverage]: number | null;
    };
  };
};

export enum GradeType {
  Reading = "readingGrade",
  LanguageArts = "languageArtsGrade",
  Participation = "participationGrade",
  Classwork = "classworkGrade",
}

export enum AbsentType {
  ReadingAbsence = "readingAbsentFromAssessment",
  LanguageArtsAbsence = "languageArtsAbsentFromAssessment",
  ClassworkAbsence = "classworkAbsentFromAssessment",
}

export type CohortExclusionDayMap = Map<Cohort["id"], IsCohortExclusionDays>;

export type WeekdayData = LocalizedWeekday & {
  isInFuture: boolean;
  notInEngagement: boolean;
  isEngExclusionDay: boolean;
  engExclusionData: IsEngagementExclusionDays;
  cohortExclusionDayMap: CohortExclusionDayMap;
};

export type NoTutoringDayDetails = {
  name: string;
  type: HolidayType;
};

export type CohortSpecialDays = {
  cohortNoShowISODays: ISODate[];
  cohortAssessmentISODays: ISODate[];
};

export type CohortDaysMap = Map<Cohort["id"], CohortSpecialDays>;

export type NoTutoringDaysMap = Map<ISODate, NoTutoringDayDetails[]>;

export type ExclusionISODays = {
  engagementNoShowISODays: ISODate[];
  engagementAssessmentISODays: ISODate[];
  cohortsExclusionISODays: CohortDaysMap;
  engagementNoTutoringISODays: NoTutoringDaysMap;
};

export type IsEngagementExclusionDays = {
  isEngagementNoShowDay: boolean;
  isEngagementAssessmentDay: boolean;
  isEngagementNoTutoringDay: NoTutoringDayDetails[];
};

export type IsCohortExclusionDays = {
  isCohortNoShowDay: boolean;
  isCohortAssessmentDay: boolean;
};

export type CohortStudentDetails = {
  id: string; // cohortStudentId
  startDate: Date;
  cohortId: string;
  cohortName: string;
  removedAt: Date | null;
};

export type DaysHolidaysMap = Map<ISODate, CellRendererHoliday[]>;
