import { NotificationCell_MissingHostNotificationFragment } from "@generated/graphql";
import { Routes } from "@utils/routes";
import { Button, ButtonLink, Icon, Link } from "components/shared";
import React from "react";

type Props = {
  notification: NonNullable<NotificationCell_MissingHostNotificationFragment>;
  showSilence: boolean;
  onDismiss: () => void;
  onSilence: () => void;
};

export function NotificationContent({
  notification,
  showSilence,
  onDismiss,
  onSilence,
}: Props) {
  return (
    <div className="flex flex-col w-full gap-y-3 p-4">
      <div className="flex w-full h-fit justify-between items-center">
        <span className="flex gap-[6px] items-center">
          <Icon icon="triangleExclamation" size={5} color="text-rose-800" />
          <div className="text-md text-gray-800 font-bold leading-none group-hover:text-rose-800">
            Missing Host
          </div>
        </span>
        <div className="flex h-fit gap-x-3 items-center justify-end">
          {showSilence && (
            <Button
              height="xs"
              className="w-fit"
              onClick={onSilence}
              theme="secondary"
            >
              Silence
            </Button>
          )}
          <Button
            height="xs"
            className="w-fit"
            theme="secondary"
            onClick={onDismiss}
          >
            Dismiss
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-y-2 gap-x-2">
        <div className="text-sm text-gray-700">
          {notification.message}{" "}
          <Link
            className="text-blue-600"
            route={Routes.engagementsDashboard}
            routeProps={[
              `engagementId=${notification.engagementId}&cohortId=${notification.cohortId}`,
            ]}
          >
            Visit cohort for details.
          </Link>
        </div>
        <div className="mt-1">
          <ButtonLink
            height="xs"
            className="w-fit bg-blue-600 hover:bg-blue-700 text-white"
            openInNewTab
            href={notification.meetingRoom}
          >
            Join Meeting
          </ButtonLink>
        </div>
      </div>
    </div>
  );
}
