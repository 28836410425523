import { Route } from "@utils/routes/types";

type CounterProps = { className?: string; bgColor?: string };

export enum SubMenuPosition {
  Last,
  Only,
  First,
  Middle,
}

export type SidebarLink = {
  name: string;
  route: Route;
  order?: number;
  current: boolean;
  disabled?: boolean;
  routeProps?: string[];
  iconClassName?: string;
  subMenuPosition?: SubMenuPosition;
  counter?: (props: CounterProps) => JSX.Element;
  icon: (props: React.ComponentProps<"svg">) => JSX.Element;
};
