import { Icon } from "components/shared";
import { IconType } from "components/shared/Icon";
import { useAuth } from "contexts/AuthProvider";
import { useState } from "react";
import { AdminMode } from "types/global";
import {
  getMentorTeacherLinks,
  getTeacherTutorLinks,
} from "./Links/TeacherLinks";
import { AdminModeSidebarSection } from "./components/AdminModeSidebarSection";
import { SidebarLinks } from "./components/SidebarLinks";
import {
  AdminModeHeight,
  getAdminModeHeights,
  getAdminModeIndex,
} from "./utils";

export const SidebarLinksContainer = () => {
  const { user, activeURL, adminMode, isAdmin, isMentor, isTeacher } =
    useAuth();

  const initHeights = getAdminModeHeights(adminMode);
  const isPaidExternally = user?.isPaidExternally ?? true;

  const [heights, setHeights] = useState<AdminModeHeight[]>(initHeights);

  const adminModesSections = [
    { mode: AdminMode.Dashboard, icon: "dashboard" },
    { mode: AdminMode.Managing, icon: "managing" },
    { mode: AdminMode.Staffing, icon: "staffing" },
    { mode: AdminMode.ControlPanel, icon: "controlPanel" },
  ];

  return (
    <div className="flex-1 flex flex-col h-auto">
      {isAdmin &&
        adminModesSections.map(({ mode, icon }) => (
          <AdminModeSidebarSection
            key={mode}
            height={heights[getAdminModeIndex(mode)]}
            setHeights={setHeights}
            adminSidebarSection={mode}
            icon={<Icon icon={icon as IconType} size={5} color="text-white" />}
          />
        ))}
      {isMentor && <SidebarLinks links={getMentorTeacherLinks(activeURL)} />}
      {isTeacher && (
        <SidebarLinks
          links={getTeacherTutorLinks(isPaidExternally, activeURL)}
        />
      )}
    </div>
  );
};
