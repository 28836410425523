import { gql } from "@apollo/client";
import { NotificationCell_MissingHostNotificationFragment } from "@generated/graphql";
import { Menu } from "@headlessui/react";
import { NotificationContent } from "./NotificationContent";
import React from "react";
import { noop } from "lodash";

NotificationCell.fragments = {
  notification: gql`
    fragment NotificationCell_MissingHostNotification on MissingHostNotification {
      id
      userId
      message
      cohortId
      engagementId
      meetingId
      dismissed
      meetingRoom
    }
  `,
};

type Props = {
  notification: NonNullable<NotificationCell_MissingHostNotificationFragment>;
  onDismiss: () => void;
};

export function NotificationCell({ notification, onDismiss }: Props) {
  // This is required as the NotificationContent component is being passed to the Menu.Item component
  // which is a part of the headlessui library. I'm not entirely sure what kind of ref related modifications
  // headlessui does, but without this wrapper component, the onDismiss function is passed in as undefined
  const ContentWrapper = React.forwardRef(() => {
    const handleDismiss = () => {
      onDismiss();
    };

    return (
      <NotificationContent
        notification={notification}
        showSilence={false}
        onDismiss={handleDismiss}
        onSilence={noop}
      />
    );
  });

  ContentWrapper.displayName = "CustomWrapper";

  return (
    <Menu.Item key="notification" disabled>
      <ContentWrapper />
    </Menu.Item>
  );
}
