import clsx from "clsx";
import { LoadingPage } from "components/shared/Loading/LoadingPage";
import { useAuth } from "contexts/AuthProvider";
import { useSidebarData } from "contexts/SidebarDataProvider";
import { useEffect, useState } from "react";
import { VisitorHeader } from "sections/Visitor/components/VisitorHeader";
import { DesktopSidebar, MobileSidebar, SidebarLinksContainer } from ".";
import { AuthHeader } from "./components/AuthHeader";

type Props = { children: React.ReactNode; visitorAccessKey?: string };

export function AuthedLayout({ children, visitorAccessKey }: Props) {
  const { fetchSidebarData } = useSidebarData();
  const { isLoadingRoute, isVisitorRoute } = useAuth();
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isVisitorRoute) fetchSidebarData();
  }, [isVisitorRoute, fetchSidebarData]);

  return (
    <div>
      {isVisitorRoute ? (
        <VisitorHeader visitorAccessKey={visitorAccessKey} />
      ) : (
        <AuthHeader setSidebarOpen={setMobileSidebarOpen} />
      )}

      <div
        className={clsx(
          "relative z-0 flex flex-col flex-1",
          isVisitorRoute ? "pl-0" : "md:pl-60"
        )}
      >
        {isLoadingRoute && <LoadingPage noSidebar={isVisitorRoute} />}
        {children}
      </div>

      {!isVisitorRoute && (
        <>
          <DesktopSidebar>
            <SidebarLinksContainer />
          </DesktopSidebar>

          <MobileSidebar
            open={mobileSidebarOpen}
            setOpen={setMobileSidebarOpen}
          >
            <SidebarLinksContainer />
          </MobileSidebar>
        </>
      )}
    </div>
  );
}
